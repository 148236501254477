// ==============================
// * [PARTIAL] FONTICONS VARIABLES
//
// ? This file gathers all the fonticons variables.
// ==============================
//
// CWF
// LAUNDRY CARE
//

// ----------------------------------------------------
// CWF
// ----------------------------------------------------

$cwf-font-family: "cwf-icomoon" !default;
$cwf-font-path: "../fonts/CwfFontico" !default;

$cwf-eye: "\e922";
$cwf-close: "\e921";
$cwf-filter: "\e920";
$cwf-arrow: "\e91f";
$cwf-google: "\e92d";
$cwf-logo: "\e91d";
$cwf-visa: "\e91e";
$cwf-paypal: "\e923";
$cwf-mastercard: "\e929";
$cwf-star: "\e92c";
$cwf-mixcloud: "\e91c";
$cwf-buble-bar: "\e900";
$cwf-buble-check: "\e901";
$cwf-buble-cross: "\e902";
$cwf-buble-i: "\e903";
$cwf-cart: "\e904";
$cwf-copy: "\e905";
$cwf-display: "\e906";
$cwf-dropdown: "\e907";
$cwf-drum: "\e908";
$cwf-email: "\e909";
$cwf-embroidery: "\e90a";
$cwf-facebook: "\e90b";
$cwf-instagram: "\e90c";
$cwf-ironing: "\e90d";
$cwf-javel: "\e90e";
$cwf-menu: "\e90f";
$cwf-notdisplay: "\e910";
$cwf-pause: "\e911";
$cwf-pen: "\e912";
$cwf-pinterest: "\e913";
$cwf-search: "\e914";
$cwf-sechage: "\e915";
$cwf-share: "\e916";
$cwf-star-empty: "\e917";
$cwf-tiktok: "\e918";
$cwf-user: "\e919";
$cwf-validate: "\e91a";
$cwf-youtube: "\e91b";


// ----------------------------------------------------
// LAUNDRY CARE
// ----------------------------------------------------

$laundry-care-font-family: "laundry-care-icomoon" !default;
$laundry-care-font-path: "../fonts/LaundryCareFontico" !default;

$laundry-care-30: "\e900";
$laundry-care-30D: "\e901";
$laundry-care-30TD: "\e902";
$laundry-care-40: "\e903";
$laundry-care-40D: "\e904";
$laundry-care-40TD: "\e905";
$laundry-care-50: "\e906";
$laundry-care-50D: "\e907";
$laundry-care-60: "\e908";
$laundry-care-95D: "\e909";
$laundry-care-BX: "\e90a";
$laundry-care-CL: "\e90b";
$laundry-care-CLX: "\e90c";
$laundry-care-MAIN: "\e90d";
$laundry-care-NSX: "\e90e";
$laundry-care-PD: "\e90f";
$laundry-care-R1P: "\e910";
$laundry-care-R2P: "\e911";
$laundry-care-R3P: "\e912";
$laundry-care-RX: "\e913";
$laundry-care-S1P: "\e914";
$laundry-care-S2P: "\e915";
$laundry-care-SAO: "\e916";
$laundry-care-SOF: "\e917";
$laundry-care-SOP: "\e918";
$laundry-care-SP: "\e919";
$laundry-care-SSF: "\e91a";
$laundry-care-SX: "\e91b";
$laundry-care-X: "\e91c";

