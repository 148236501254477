// ==============================
// * PAGE DESIGNER: SLIDER
//
// ? This file gathers all the custom Slider CSS.
// ==============================
//
// SLIDER
//    ARROWS
//    DOTS
//    PAGED

@import "../../variables";
@import "../../../abstract/functions";
@import "../../../abstract/mixins";
@import "../../../fonts/variables";
@import "bootstrap/scss/mixins/breakpoints";


// ----------------------------------------------------
// SLIDER
// ----------------------------------------------------

.slider,
.list-slider {
    width: 100%;
    padding-top: 60px;

    .slick-slide {
        @include make-transition(all 0.2s ease-in-out);

        margin: 0 7.5px;
        padding: 0;

        img {
            width: 100%;
        }
    }

    // ARROWS
    // ----------------------------------------------------

    .slick-prev,
    .slick-next {
        @include make-transition(all 0.2s ease-in-out);

        position: absolute;
        top: 0;
        display: block;
        outline: none;
        width: 40px;
        height: 40px;
        cursor: pointer;
        line-height: 0;
        font-size: 0;
        border: 1px solid $black;
        padding: 0;
        background: $black;
        color: transparent;
        border-radius: $border-radius-sm;

        &::before {
            content: $cwf-dropdown;
            opacity: 1;
            font-size: rem(20px);
            line-height: 1;
            font-family: $cwf-font-family;
            color: $white;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        &:hover,
        &:focus-visible {
            outline: none;
            background: $white;
            color: transparent;

            &::before {
                color: $black;
            }
        }

        &.slick-disabled {
            opacity: 0.3;
            pointer-events: none;
            background: $white;

            &::before {
                color: $black;
            }
        }

        &.disabled {
            pointer-events: none;
            background: $white;

            &::before {
                color: $black;
            }
        }
    }

    .slick-prev {
        left: auto;
        right: 65px;
        transform: rotate(90deg);
    }

    .slick-next {
        right: 15px;
        transform: rotate(-90deg);
    }

    // DOTS
    // ----------------------------------------------------

    .slick-dots {
        position: absolute;
        bottom: 0;
        display: block;
        margin: 0;
        width: 100%;
        padding: 0;
        list-style: none;
        text-align: left;

        @include media-breakpoint-up(lg) {
            position: relative;
            bottom: auto;
            text-align: center;
        }

        li {
            position: relative;
            display: inline-block;
            width: 12px;
            margin: 0 4px;
            height: 12px;
            padding: 0;
            cursor: pointer;

            button {
                display: block;
                outline: none;
                border: 1px solid $black;
                border-radius: $border-radius-circle;
                padding: 0;
                width: 12px;
                height: 12px;
                background: $white;
                cursor: pointer;
                font-size: 0;
                line-height: 0;

                @include media-breakpoint-up(lg) {
                    &:hover,
                    &.active {
                        &::before {
                            background: $black;
                        }
                    }
                }

                &::before {
                    @include make-transition(all 0.2s ease-in-out);

                    content: "";
                    display: block;
                    margin: 2px;
                    border-radius: $border-radius-circle;
                    width: 6px;
                    height: 6px;
                    background: $white;
                }
            }

            &.slick-active {
                button {
                    &::before {
                        opacity: 1;
                        background: $black;
                    }
                }
            }
        }
    }

    // PAGED
    // ----------------------------------------------------

    &-paged {
        .slick-dots {
            @include media-breakpoint-up(lg) {
                position: absolute;
                top: 0;
                right: 100px;
                bottom: auto;
                left: auto;
                text-align: right;
                background: $yellow;
                width: auto;

                li {
                    position: absolute;
                    opacity: 0;

                    &::before {
                        display: none;
                    }

                    &.slick-active {
                        opacity: 1;
                    }

                    button {
                        border: none;
                        border-radius: 0;
                        width: calc(#{$input-btn-padding-y} * 4);
                        height: calc(#{$input-btn-padding-y} * 4);
                        font-size: $font-size-base;
                        line-height: calc(#{$input-btn-padding-y} * 4);
                        white-space: nowrap;

                        &::before {
                            display: none;
                        }
                    }
                }
            }

            li {

                @include media-breakpoint-up(lg) {
                    &:hover,
                    &.active {
                        &::before {
                            background: $black;
                        }
                    }
                }

                &::before {
                    @include make-transition(all 0.2s ease-in-out);

                    content: "";
                    display: block;
                    margin: 2px;
                    border-radius: $border-radius-circle;
                    width: 6px;
                    height: 6px;
                    background: $white;
                }
            }
        }

        .slick-prev {
            right: 100px;
        }

        .slick-next {
            right: 10px;
        }

        .slick-track {
            min-width: 100% !important;
        }
    }
}
