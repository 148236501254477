// ==============================
// * [PARTIAL] MIXINS
//
// ? This file gathers all the custom functions.
// ==============================
//
// LAYOUT
// APPEARANCE
// FONTS
//    TITLES
// EFFECTS
// LINKS
// ERROR MESSAGE
// NOTIFICATION
//

@import "../variables";
@import "bootstrap/scss/mixins";

// -------------------------------------
// LAYOUT
// -------------------------------------

@mixin element-centered-X-Y { /* stylelint-disable-line */
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

@mixin element-centered-X { /* stylelint-disable-line */
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
}

@mixin element-centered-Y { /* stylelint-disable-line */
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
}


//-------------------------------------
// APPEARANCE
//-------------------------------------

@mixin appearance-none() {
    appearance: none;
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}


// -------------------------------------
// FONTS
// -------------------------------------

@mixin make-icon($icon-size) {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "#{$cwf-font-family}" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::before {
        font-size: $icon-size;
    }
}

// TITLES
// -------------------------------------

@mixin big-h1() {
    font-size: $font-size-big-h1-mobile;
    font-weight: $font-weight-bold;
    letter-spacing: 4px;
    text-transform: none;

    @include media-breakpoint-up(sm) {
        font-size: $font-size-big-h1-desktop;
    }
}

@mixin h1() { /* stylelint-disable-line */
    font-size: $font-size-h1-mobile;
    font-weight: $font-weight-black;
    text-transform: none;

    @include media-breakpoint-up(sm) {
        font-size: $font-size-h1-desktop;
    }
}

@mixin h2() { /* stylelint-disable-line */
    font-size: $font-size-h2-mobile;
    font-weight: $font-weight-bold;
    text-transform: uppercase;

    @include media-breakpoint-up(sm) {
        font-size: $font-size-h2-desktop;
    }
}

@mixin h3() { /* stylelint-disable-line */
    font-size: $font-size-h3-mobile;
    font-weight: $font-weight-black;

    @include media-breakpoint-up(sm) {
        font-size: $font-size-h3-desktop;
    }
}


// -------------------------------------
// EFFECTS
// -------------------------------------

@mixin make-transition($property: all, $duration: $duration-default, $function: $timing-function-default, $delay: null) {
    transition: $property $duration $function $delay;
}


// -------------------------------------
// LINKS
// -------------------------------------

a.disabled {
    pointer-events: none;
    cursor: pointer;
}


// -------------------------------------
// ERROR MESSAGE
// -------------------------------------

@mixin error-message() {
    display: inline-block;
    padding: 6px $grid-gutter-width;
    background-color: $danger;
    color: $white;
    text-transform: uppercase;
    text-align: center;

    @include media-breakpoint-down(xs) {
        width: 100%;
        margin-top: $grid-gutter-width / 2;
        padding: 6px;
        font-size: rem(10px);
    }
}


// -------------------------------------
// NOTIFICATION
// -------------------------------------

@mixin notification() {
    background-color: $black;
    border: 1px solid $black;
    border-radius: 0;
    box-shadow: none;
    color: $white;
}
