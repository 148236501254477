// ==============================
// * PAGE DESIGNER: HERO SLIDER
//
// ? This file gathers all the hero slider CSS.
// ==============================

@import "slider";

.hero-slider {
    &__container.slider {
        padding-top: 0;

        .slick-slide {
            margin: 0;
        }
    }
}
